<template>
  <div class="feast">
    <div class="main">
      <Head />
      <div class="bg">
        <img class="bg-in" src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/Circle.png" alt="">
      </div>
      <div class="photo">
        <img class="listen" src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/T143.png" alt="">
        <img
          class="t3 cursor"
          src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/t3.png"
          alt=""
          @click="videoClick('video')"
        >
        <img
          class="t1 cursor"
          src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/t1.png"
          alt=""
          @click="expect('music')"
        >
        <img
          class="t2 cursor"
          src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/t2.png"
          alt=""
          @click="videoClick('img')"
        >
      </div>
      <div v-show="bgShow" class="all-bg" @click="expect">
        <span>敬请期待...</span>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "@/components/Head";
export default {
  name: "Backdrop",
  components: { Head },
  data() {
    return {
      bgShow: false
    };
  },
  methods: {
    videoClick(type) {
      this.$router.push({
        path: "./soundtrack",
        query: {
          type
        }
      });
    },
    expect() {
      if (this.bgShow === false) {
        this.bgShow = true;
      } else {
        this.bgShow = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes changeleft {
  0% {
    -webkit-transform: rotate(360deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
.main {
  height: 100vh;
  width: 100%;
  // height: 950px;
  background: url("https://thcdn.gggamedownload.com/source/assets/shitingshengyan/bg.jpg") no-repeat center;
  background-size: 100% 100%;
  position: fixed;
  .bg {
    width: 100%;
    // height: 950px;
    background: url("https://thcdn.gggamedownload.com/source/assets/shitingshengyan/bg11.png") no-repeat center;
    .bg-in {
      width: 932px;
      display: block;
      margin: 0 auto;
      // -webkit-animation: changeright 10s linear infinite;
    }
  }
  .photo {
    .listen {
      position: absolute;
      width: 403px;
      left: 50%;
      top: 42%;
      transform: translateX(-50%);
    }
    .t3 {
      position: absolute;
      left: 50%;
      top: 16%;
      width: 256px;
      transform: translateX(-50%);
    }
    .t1 {
      position: absolute;
      left: 30%;
      top: 56.5%;
      width: 256px;

    }
    .t2 {
      position: absolute;
      width: 256px;
      left: 56.5%;
      top: 56.5%;
    }
  }
  .all-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.63;
    span {
      border: 1px solid #fff;
      font-size: 28px;
      color: #fff;
      padding: 0 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      text-align: center;
      line-height: 60px;
    }
  }
}
.cursor {
  cursor: pointer;
}
</style>
