<template>
  <div class="visual">
    <Backdrop />
  </div>
</template>

<script>
import Backdrop from './models/Backdrop'
export default {
  name: "Visual",
  components: { Backdrop },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.visual {
}
</style>
